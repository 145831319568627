<template>
    <admin-template>
        <template slot="content">
            <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                <v-toolbar-title>
                    <span class="headline">Parametros de Configuración</span>
                </v-toolbar-title>
            </v-toolbar>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-simple-table>
                                <tbody>
                                    <tr v-for="(value, key) in parameters" v-bind:key="key">
                                        <td style="width: 300px;">{{ key }}</td>
                                        <td>
                                            <v-text-field
                                                style="width: 500px"
                                                v-model="parameters[key]"
                                                solo
                                                flat
                                                outlined
                                                hide-details
                                                dense
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>

                        <v-card-actions align="right">
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                @click="onSaveSelected"
                                :loading="isSaving"
                            >Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </admin-template>
</template>

<script>
import AdminMenu from '../AdminMenu.vue';
import AdminTemplate from '../AdminTemplate.vue';

export default {
    components: {
        AdminMenu,
        AdminTemplate
    },

    data() {
        return {
            isSaving: false,

            headers: [
                { text: 'Key', value: 'key', sortable: false },
                { text: 'Value', value: 'value', sortable: false }
            ]
        };
    },

    computed: {
        isLoading() {
            return this.$store.getters['parameters/isLoading']();
        },

        parameters() {
            return this.$store.getters['parameters/getAll']();
        }
    },

    watch: {
    },

    mounted() {
        this.$store.dispatch('parameters/fetch').then(() => {

        });
    },

    methods: {
        onSaveSelected() {
            this.isSaving = true;

            this.$store.dispatch('parameters/update', this.parameters).finally(() => {
                this.isSaving = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
